<template>
  <base-modal
      @close-modal="close"
  >
    <base-modal-template
        @close-modal="close"
    >
      <template #top>
        Edit question
      </template>
      <template #bottom>
        <div class="edit-question-modal__info">
          <p>
            Edit question to suit your needs
          </p>
          <base-input v-model="question" />
        </div>
        <div class="edit-question-modal__btns">
          <base-simple-button
              class="edit-question-modal__cancel"
              @click="close"
          >
            Cancel
          </base-simple-button>
          <base-button
              class="edit-question-modal__confirm"
              @click="confirm"
              :disabled="!question"
          >
            Confirm
          </base-button>
        </div>
      </template>
    </base-modal-template>
  </base-modal>
</template>
<script setup>
import { ref } from 'vue'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalTemplate from '@/components/modals/BaseModalTemplate'
import BaseSimpleButton from '@/components/core/BaseSimpleButton'
import BaseButton from '@/components/core/BaseButton'
import BaseInput from '@/components/core/BaseInput'

import { useModalsStore } from '@/store/ModalsStore'
const modalsStore = useModalsStore()

const question = ref(modalsStore.editQuestionModal.data?.question || '')

const close = () => {
  modalsStore.hideModal('editQuestionModal')
}
const confirm = () => {
  if (modalsStore.editQuestionModal.successCallback) {
    modalsStore.editQuestionModal.successCallback(question.value)
  }
  modalsStore.hideModal('editQuestionModal')
}
</script>
<style lang="scss" scoped>
.edit-question-modal {
  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 72px;
  }
  &__cancel {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $WHITE;
    cursor: pointer;
    margin-right: 16px;
  }
  &__confirm {
    max-width: unset;
    width: auto;
    padding: 0 62px;
    height: 64px;
  }
  &__info {
    p {
      font-size: 14px;
      font-weight: 500;
      color: $TEXT_GRAY;
      margin-bottom: 8px;
    }
  }
}
</style>