<template>
  <base-modal
      @close-modal="close"
  >
    <base-modal-template
        @close-modal="close"
    >
      <template #top>
        Thank you
      </template>
      <template #bottom>
        <div class="thanks-modal__info">
          <p>
            We'll contact you you within 24 hours!
          </p>
        </div>
        <div class="thanks-modal__btns">
          <base-button
              class="thanks-modal__confirm"
              @click="confirm"
          >
            Ok
          </base-button>
        </div>
      </template>
    </base-modal-template>
  </base-modal>
</template>
<script setup>
import BaseModal from '@/components/modals/BaseModal'
import BaseModalTemplate from '@/components/modals/BaseModalTemplate'
import BaseButton from '@/components/core/BaseButton'

import { useModalsStore } from '@/store/ModalsStore'
const modalsStore = useModalsStore()

const close = () => {
  modalsStore.hideModal('thanksModal')
}
const confirm = () => {
  if (modalsStore.thanksModal.successCallback) {
    modalsStore.thanksModal.successCallback()
  }
  modalsStore.hideModal('thanksModal')
}
</script>
<style lang="scss" scoped>
.thanks-modal {
  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 72px;
  }
  &__confirm {
    max-width: unset;
    width: auto;
    padding: 0 62px;
    height: 64px;
  }
}
</style>