<template>
  <base-modal
      @close-modal="close"
  >
    <base-modal-template
        @close-modal="close"
    >
        <template #top>
          Delete question
        </template>
      <template #bottom>
        <div class="delete-question-modal__info">
          <p>
            Are you sure you want to delete this question?
          </p>
        </div>
        <div class="delete-question-modal__btns">
          <base-simple-button
              class="delete-question-modal__cancel"
              @click="close"
          >
            Cancel
          </base-simple-button>
            <base-alert-button
                class="delete-question-modal__delete"
                @click="remove"
            >
              delete
            </base-alert-button>
        </div>
      </template>
    </base-modal-template>
  </base-modal>
</template>
<script setup>
import BaseModal from '@/components/modals/BaseModal'
import BaseModalTemplate from '@/components/modals/BaseModalTemplate'
import BaseSimpleButton from '@/components/core/BaseSimpleButton'
import BaseAlertButton from '@/components/core/BaseAlertButton'

import { useModalsStore } from '@/store/ModalsStore'
const modalsStore = useModalsStore()

const close = () => {
  modalsStore.hideModal('deleteQuestionModal')
}
const remove = () => {
  if (modalsStore.deleteQuestionModal.successCallback) {
    modalsStore.deleteQuestionModal.successCallback()
  }
  modalsStore.hideModal('deleteQuestionModal')
}
</script>
<style lang="scss" scoped>
.delete-question-modal {
  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 72px;
  }
  &__cancel {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $WHITE;
    cursor: pointer;
    margin-right: 16px;
  }
}
</style>