import { defineStore } from 'pinia'

export const useModalsStore = defineStore('modals', {
    state: () => ({
        deleteQuestionModal: {
            isShown: false,
            successCallback: null,
            cancelCallback: null,
            data: null
        },
        editQuestionModal: {
            isShown: false,
            successCallback: null,
            cancelCallback: null,
            data: null
        },
        addQuestionModal: {
            isShown: false,
            successCallback: null,
            cancelCallback: null,
            data: null
        },
        generateQuestionModal: {
            isShown: false,
            successCallback: null,
            cancelCallback: null,
            data: null
        },
        thanksModal: {
            isShown: false,
            successCallback: null,
            cancelCallback: null,
            data: null
        },
        contactsModal: {
            isShown: false,
            successCallback: null,
            cancelCallback: null,
            data: null
        },
    }),
    actions: {
        showModal(modal, { successCallback = null, cancelCallback = null, data = null } = {}) {
            this[modal].isShown = true
            this[modal].successCallback = successCallback
            this[modal].cancelCallback = cancelCallback
            this[modal].data = data
        },
        hideModal(modal) {
            this[modal].isShown = false
            this[modal].successCallback = null
            this[modal].cancelCallback = null
            this[modal].data = null
        }
    },
})