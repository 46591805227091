import { createApp } from 'vue'
import InlineSvg from 'vue-inline-svg';
import { createPinia } from 'pinia'
import Toast from 'vue-toastification';
import * as amplitude from '@amplitude/analytics-browser';
import MasonryWall from '@yeger/vue-masonry-wall'

import App from './App.vue'
import { router } from './router'

import './styles/settings.scss'
import 'vue-toastification/dist/index.css';

amplitude.init('97bd5728cf8f118c1c9ed4d7ecff837a');

createApp(App)
    .use(router)
    .use(createPinia())
    .use(Toast)
    .use(MasonryWall)
    .component('inline-svg', InlineSvg)
    .mount('#app')
