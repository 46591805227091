<template>
  <base-modal
      @close-modal="close"
  >
    <base-modal-template
        @close-modal="close"
    >
      <template #top>
        <p class="generate-question-modal__title">
          Let i1 generate more questions
        </p>
        <p class="generate-question-modal__description">
          Specify tools or frameworks, and i1 creates tailored questions
        </p>
      </template>
      <template #bottom>
        <div class="generate-question-modal__info">
          <base-input
              v-model="question"
              placeholder="e.g. AWS, Laravel"
          />
        </div>
        <div class="generate-question-modal__btns">
          <base-simple-button
              class="generate-question-modal__cancel"
              @click="close"
          >
            Cancel
          </base-simple-button>
          <base-button
              class="generate-question-modal__confirm"
              @click="confirm"
          >
            Generate
          </base-button>
        </div>
      </template>
    </base-modal-template>
  </base-modal>
</template>
<script setup>
import { ref } from 'vue'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalTemplate from '@/components/modals/BaseModalTemplate'
import BaseSimpleButton from '@/components/core/BaseSimpleButton'
import BaseButton from '@/components/core/BaseButton'
import BaseInput from '@/components/core/BaseInput'

import { useModalsStore } from '@/store/ModalsStore'
const modalsStore = useModalsStore()

const question = ref('')

const close = () => {
  modalsStore.hideModal('generateQuestionModal')
}
const confirm = () => {
  if (modalsStore.generateQuestionModal.successCallback) {
    modalsStore.generateQuestionModal.successCallback(question.value)
  }
  modalsStore.hideModal('generateQuestionModal')
}
</script>
<style lang="scss" scoped>
.generate-question-modal {
  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 72px;
  }
  &__cancel {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $WHITE;
    cursor: pointer;
    margin-right: 16px;
  }
  &__confirm {
    max-width: unset;
    width: auto;
    padding: 0 62px;
    height: 64px;
  }
  &__description {
    color: $LIGHT_WHITE;
    font-size: 16px;
    font-weight: 500;
    margin-top: 4px;
  }
}
</style>