<template>
  <base-modal
      @close-modal="close"
  >
    <base-modal-template
        @close-modal="close"
    >
      <template #top>
        <p class="contacts-modal__title">
          Tell us a little bit about your yourself
        </p>
        <p class="contacts-modal__description">
          We will send you top candidate matches and vetting updates
        </p>
      </template>
      <template #bottom>
        <div class="contacts-modal__info">
          <div class="contacts-modal__input">
            <base-input
                v-model="form.firstName"
                placeholder="First name"
            />
          </div>
          <div class="contacts-modal__input">
            <base-input
                v-model="form.lastName"
                placeholder="Last name"
            />
          </div>
          <div class="contacts-modal__input">
            <base-input
                v-model="form.email"
                placeholder="Business email"
            />
          </div>
          <div class="contacts-modal__input">
            <base-input
                v-model="form.companyName"
                placeholder="Company name"
            />
          </div>
        </div>
        <div class="contacts-modal__btns">
          <base-button
              class="contacts-modal__confirm"
              @click="confirm"
              :disabled="!isValid"
          >
            Send
          </base-button>
        </div>
      </template>
    </base-modal-template>
  </base-modal>
</template>
<script setup>
import * as amplitude from '@amplitude/analytics-browser'
import { reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import { useUserStore } from '@/store/UserStore'
import { useLoaderStore } from '@/store/LoaderStore'
import { UserService } from '@/services/UserService'

const userService = new UserService()
const userStore = useUserStore()
const loaderStore = useLoaderStore()

import BaseModal from '@/components/modals/BaseModal'
import BaseModalTemplate from '@/components/modals/BaseModalTemplate'
import BaseButton from '@/components/core/BaseButton'
import BaseInput from '@/components/core/BaseInput'

import { useModalsStore } from '@/store/ModalsStore'
const modalsStore = useModalsStore()

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  companyName: ''
})

const rules = {
  firstName: {
    required
  },
  lastName: {
    required
  },
  email: {
    required,
    email
  },
  companyName: {
    required
  }
};

const validator = useVuelidate(
    rules, form
);

const isValid = computed(() => {
  return validator.value.firstName.$silentErrors.length === 0 &&
      validator.value.lastName.$silentErrors.length === 0 &&
      validator.value.email.$silentErrors.length === 0 &&
      validator.value.companyName.$silentErrors.length === 0;
});

const close = () => {
  modalsStore.hideModal('contactsModal')
}
const confirm = () => {
  loaderStore.showLoader()
  amplitude.track('i1: Contact info submitted')
  userService.setUser({
    session_id: userStore.sessionId,
    first_name: form.firstName,
    last_name: form.lastName,
    business_email: form.email,
    company_name: form.companyName
  }).then((res) => {
    userStore.setUser({
      sessionId: res.data.data.session_id,
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      companyName: form.companyName
    })
    if (modalsStore.contactsModal.successCallback) {
      modalsStore.contactsModal.successCallback()
    }
    modalsStore.hideModal('contactsModal')
  }).finally(() => {
    loaderStore.hideLoader()
  })
}
</script>
<style lang="scss" scoped>
.contacts-modal {
  &__btns {
    margin-top: 64px;
  }
  &__confirm {
    max-width: 100%;
    width: 100%;
    padding: 0 62px;
    height: 64px;
  }
  &__description {
    color: $LIGHT_WHITE;
    font-size: 16px;
    font-weight: 500;
    margin-top: 4px;
  }
  &__input {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>