<template>
  <div class="base-modal-template">
    <div class="base-modal-template__top">
      <slot name="top"></slot>
      <div
          class="base-modal-template__close"
          @click="closeModal"
      >
        <x-icon class="base-modal-template__close__icon" />
      </div>
    </div>
    <div class="base-modal-template__bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>
<script setup>
import { defineEmits } from 'vue'
import { XIcon } from '@heroicons/vue/outline'
const emit = defineEmits(['closeModal'])

const closeModal = () => {
  emit('closeModal')
}
</script>
<style lang="scss" scoped>
.base-modal-template {
  max-width: 656px;
  width: 100%;
  background: radial-gradient(106.32% 210.25% at 14.13% 50%, #1E1F24 0%, #27282C 100%);
  border-radius: 16px;
  &__top {
    padding: 32px 100px 32px 40px;
    background: linear-gradient(90.35deg, #28282F 0%, #212123 100%);
    position: relative;
    border-radius: 16px 16px 0 0;
    font-size: 24px;
    font-weight: 800;
  }
  &__close {
    position: absolute;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 24px;
    top: 24px;
    cursor: pointer;
    &__icon {
      width: 24px;
      height: 24px;
      color: $TEXT_GRAY;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: $WHITE;
      }
    }
  }
  &__bottom {
    padding: 40px 40px 24px 40px;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>