import {
    createRouter, createWebHistory
} from 'vue-router';
const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('@/pages/MainPage.vue')
    },
    {
        path: '/vetting',
        name: 'Vetting',
        component: () => import('@/pages/VettingPage.vue')
    }
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})