<template>
  <div>
    <router-view />
    <base-loader v-if="loaderStore.loading" />
    <delete-question-modal v-if="modalsStore.deleteQuestionModal.isShown" />
    <edit-question-modal v-if="modalsStore.editQuestionModal.isShown" />
    <add-question-modal v-if="modalsStore.addQuestionModal.isShown" />
    <generate-question-modal v-if="modalsStore.generateQuestionModal.isShown" />
    <thanks-modal v-if="modalsStore.thanksModal.isShown" />
    <contacts-modal v-if="modalsStore.contactsModal.isShown" />
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import * as amplitude from '@amplitude/analytics-browser'
import BaseLoader from "@/components/core/BaseLoader"
import DeleteQuestionModal from '@/components/modals/DeleteQuestionModal'
import EditQuestionModal from '@/components/modals/EditQuestionModal'
import AddQuestionModal from '@/components/modals/AddQuestionModal'
import GenerateQuestionModal from '@/components/modals/GenerateQuestionModal'
import ThanksModal from '@/components/modals/ThanksModal'
import ContactsModal from '@/components/modals/ContactsModal'

import { useLoaderStore } from '@/store/LoaderStore'
import { useModalsStore } from '@/store/ModalsStore'
const loaderStore = useLoaderStore()
const modalsStore = useModalsStore()

onMounted(() => {
  amplitude.track('i1: Page view')
})

</script>
