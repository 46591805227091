<template>
  <div class="base-simple-button">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.base-simple-button {
  display: inline-block;
  padding: 0 62px;
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: $WHITE;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 12px;
  text-align: center;
  white-space: nowrap;
  @media all and (max-width: 1023px) {
    font-size: 15px;
    padding: 0 30px;
    height: 60px;
  }
  &:hover {
    background: $LIGHT_GRAY;
  }
}
</style>