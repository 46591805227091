import axios from 'axios'
import { useToast } from 'vue-toastification'
const toast = useToast()

export class HttpService {
    constructor () {
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL,
        })
        this.axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const errors = error.response?.data?.data?.errors;
                if (errors?.length) {
                    let message = ''
                    for (let i = 0; i < errors.length; i ++) {
                        message += `${errors[i].message} - ${errors[i].property} \n`;
                    }
                    toast.error(message);
                } else {
                    toast.error('Something went wrong.');
                }
                return Promise.reject(error);
            }
        );
    }
}