<template>
  <div class="base-modal">
    <div class="base-modal__wrap">
      <div
          class="base-modal__layer"
          @click="closeModal"
      />
      <div class="base-modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['closeModal'])
const closeModal = () => {
  emit('closeModal')
}
</script>
<style lang="scss" scoped>
.base-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
  &__layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(68, 68, 72, 0.8);
    z-index: -1;
  }
  &__content {
    // TODO need to fix to fit content
    max-width: 656px;
    width: 100%;
  }
  &__wrap {
    min-height: 100vh;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 2;
    color: $WHITE;
  }
}
</style>