import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null
    }),
    getters: {
      sessionId (state) {
        return state.user?.sessionId || 0
      }
    },
    actions: {
        setUser(value) {
            this.user = {
                ...this.user,
                ...value
            }
        }
    },
})